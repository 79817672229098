import { Button } from '../Button';

export const CTAButton = ({ onClick, ctaTitle }) => {
  if (!onClick) return null;

  return (
    <Button
      className="text-link ml-2 flex items-center gap-1 bg-transparent p-0 text-sm font-semibold md:ml-0 md:mr-2"
      onClick={onClick}
      variant="invisible"
      data-testid="cta-button-desktop"
    >
      {ctaTitle}
    </Button>
  );
};
