import { fbEvent } from '../conversionApi';

import { FBCustomerInfo } from '../types';

type Props = {
  eventId?: string;
  name: string;
  category?: string;
  customer?: FBCustomerInfo;
};

const getDefaultProps = ({ eventId, name, category, customer }: Props) => {
  return {
    ...(customer || {}),
    eventId,
    contentName: name,
    contentCategory: category,
  };
};

export const pageView = async (props: Props) => {
  const eventData = getDefaultProps(props);
  await fbEvent({
    ...eventData,
    eventName: 'PageView',
  });
};

export const viewContent = async (props: Props) => {
  const eventData = getDefaultProps(props);
  await fbEvent({
    ...eventData,
    eventName: 'ViewContent',
  });
};

export const addToBasket = async ({
  sku,
  qty,
  price,
  currency,
  ...props
}: Props & {
  sku: string;
  qty: number;
  price: number;
  currency: string;
}) => {
  const eventData = getDefaultProps(props);
  await fbEvent({
    ...eventData,
    eventName: 'AddToCart',
    products: [{ sku, qty, price }],
    currency,
  });
};
