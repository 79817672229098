import type { IconProps as Props } from './types';

export const IconEllipse = ({
  className,
  width = '1em',
  height = '1em',
  fill = 'none',
  ...rest
}: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={fill}
      {...rest}
    >
      <circle cx="6" cy="6" r="5" stroke="white" strokeWidth="2" />
    </svg>
  );
};
