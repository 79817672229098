import { useState } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { sizeConfig } from './config';
import { Indicators } from './Indicators';
import { Item } from './Item';

export type TrackProps = {
  className?: string;
  children: any;
  carouselRef: any;
  firstChildRef: any;
  lastChildRef: any;
  perPage?: {
    mobile?: keyof typeof sizeConfig['mobile'];
    tablet?: keyof typeof sizeConfig['tablet'];
    desktop?: keyof typeof sizeConfig['desktop'];
    wide?: keyof typeof sizeConfig['wide'];
  };
  showPageIndicators?: boolean;
  isProductCarousel?: boolean;
};

const setSizeClasses = (size: TrackProps['perPage']) => {
  const { mobile = 'default', tablet = 2, desktop = 3, wide = 3 } = size;

  const mobileSize = sizeConfig.mobile[mobile];
  const tabletSize = sizeConfig.tablet[tablet];
  const desktopSize = sizeConfig.desktop[desktop];
  const widescreenSize = sizeConfig.wide[wide];

  return cn(mobileSize, tabletSize, desktopSize, widescreenSize);
};

export const Track = ({
  className,
  carouselRef,
  children,
  firstChildRef,
  lastChildRef,
  perPage = {
    mobile: 'default',
    tablet: 3,
    desktop: 4,
    wide: 5,
  },
  showPageIndicators,
  isProductCarousel,
}: TrackProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleCurrentIndex = (index: number) => {
    setActiveIndex(index);
  };

  if (!children?.length) return null;

  return (
    <>
      <ul
        className={cn(
          'track -mx-4 mb-0 mt-0 flex flex-1 gap-4 pe-px ps-0',
          'hide-scrollbar snap-x snap-mandatory scroll-p-4 overflow-x-auto overflow-y-hidden scroll-smooth',
          'md:mx-0 md:scroll-p-0',
          'lg:mx-auto',
          setSizeClasses(perPage),
          isProductCarousel &&
            'children:max-w-[232px] children:sm:max-w-[320px] children:md:max-w-[unset]',
          className
        )}
        data-testid="carousel-track"
        ref={carouselRef}
      >
        <ChildrenRenderer
          firstChildRef={firstChildRef}
          lastChildRef={lastChildRef}
          isProductCarousel={isProductCarousel}
          handleCurrentIndex={handleCurrentIndex}
          disabledObserver={!showPageIndicators}
        >
          {children}
        </ChildrenRenderer>
      </ul>
      {showPageIndicators && (
        <Indicators activeIndex={activeIndex} amountOfPages={children.length} />
      )}
    </>
  );
};

export const ChildrenRenderer = ({
  children,
  firstChildRef,
  lastChildRef,
  isProductCarousel,
  handleCurrentIndex,
  disabledObserver,
}) => {
  if (Array.isArray(children)) {
    return children.map((child, index) => {
      const childItemProps = {
        index,
        handleCurrentIndex,
        disabledObserver,
        isProductCarousel,
      };
      // first
      if (index === 0) {
        return (
          <Item
            key={`first-child-${index}`}
            data-testid="first-child"
            ref={firstChildRef}
            {...childItemProps}
          >
            {child}
          </Item>
        );
      }
      // last
      if (index === children.length - 1) {
        return (
          <Item
            key={`last-child-${index}`}
            data-testid="last-child"
            ref={lastChildRef}
            {...childItemProps}
          >
            {child}
          </Item>
        );
      }
      return (
        <Item key={`children-${index}`} data-testid="children" {...childItemProps}>
          {child}
        </Item>
      );
    });
  }

  return children;
};
