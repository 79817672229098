import { RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
  deps?: any[];
  disabled?: boolean;
}

const useIntersectionObserver = (
  elementRef: RefObject<Element | undefined>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
    deps = [],
    disabled,
  }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    if (disabled) return;

    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => {
      if (!hasIOSupport || frozen || !node) return;
      observer.unobserve(node);
    };
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen, ...deps]);

  return entry;
};

export default useIntersectionObserver;
