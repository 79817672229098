import { useState, useMemo, RefObject, ButtonHTMLAttributes } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import useIntersectionObserver from '@virginexperiencedays/hooks/src/useIntersectionObserver';

import { isDeviceAppleSafari } from '../../utils/device';
import { Button } from '../Button';

type CarouselNavsProps = {
  carouselRef?: any;
  lastChildRef?: any;
  firstChildRef?: any;
  debounceTime?: number;
  index?: number;
};

export const CarouselNavs = ({
  carouselRef,
  firstChildRef,
  lastChildRef,
  debounceTime,
  index,
}: CarouselNavsProps) => {
  if (Array.isArray(carouselRef?.current)) {
    return (
      <ArrayRefNavs
        carouselRef={carouselRef}
        lastChildRef={lastChildRef}
        firstChildRef={firstChildRef}
        debounceTime={debounceTime}
        index={index}
      />
    );
  }

  return (
    <SingleRefNavs
      carouselRef={carouselRef}
      lastChildRef={lastChildRef}
      firstChildRef={firstChildRef}
      debounceTime={debounceTime}
    />
  );
};

// @TODO : Merge Array/SingleRefNavs into one component without conflicting Intersection Observer
const SingleRefNavs = ({
  carouselRef,
  firstChildRef,
  lastChildRef,
  debounceTime,
}: CarouselNavsProps) => {
  const firstElementEntry = useIntersectionObserver(firstChildRef, {
    threshold: 0.95,
    root: carouselRef?.current,
  });
  // Defaults to true when element refs are not yet ready
  const isFirstChildVisible = !firstElementEntry || !!firstElementEntry?.isIntersecting;

  const lastElementEntry = useIntersectionObserver(lastChildRef, {
    threshold: 0.95,
    root: carouselRef?.current,
  });
  const isLastChildVisible = !!lastElementEntry?.isIntersecting;

  const [isScrolling, setIsScrolling] = useState(false);

  // Prevent spamming by debounce; Ensure getting the updated carouselRef on click
  const handleForward = () => {
    if (isScrolling) return;
    const carouselEl = carouselRef?.current;

    setIsScrolling(true);
    carouselEl?.scrollBy(getScrollBy(carouselEl, 'next'), 0);

    setTimeout(() => {
      setIsScrolling(false);
    }, debounceTime);
  };

  // Prevent spamming by debounce; Ensure getting the updated carouselRef on click
  const handleBackward = () => {
    if (isScrolling) return;
    const carouselEl = carouselRef?.current;

    setIsScrolling(true);
    carouselEl?.scrollBy(getScrollBy(carouselEl, 'prev'), 0);

    setTimeout(() => {
      setIsScrolling(false);
    }, debounceTime);
  };

  return (
    <Navs
      handleBackward={handleBackward}
      handleForward={handleForward}
      isFirstChildVisible={isFirstChildVisible}
      isLastChildVisible={isLastChildVisible}
    />
  );
};

const ArrayRefNavs = ({
  carouselRef,
  firstChildRef,
  lastChildRef,
  debounceTime,
  index,
}: CarouselNavsProps) => {
  const firstChildEl = useMemo(
    () => ({ current: firstChildRef?.current?.[index] }),
    [firstChildRef?.current?.[index]]
  ) as RefObject<HTMLElement>;
  const lastChildEl = useMemo(
    () => ({ current: lastChildRef?.current?.[index] }),
    [lastChildRef?.current?.[index]]
  ) as RefObject<HTMLElement>;

  const firstElementEntry = useIntersectionObserver(firstChildEl, {
    threshold: 0.95,
    root: carouselRef?.current?.[index],
  });
  // Defaults to true when element refs are not yet ready
  const isFirstChildVisible = !firstElementEntry || !!firstElementEntry?.isIntersecting;

  const lastElementEntry = useIntersectionObserver(lastChildEl, {
    threshold: 0.95,
    root: carouselRef?.current?.[index],
  });
  const isLastChildVisible = !!lastElementEntry?.isIntersecting;

  const [isScrolling, setIsScrolling] = useState(false);

  // Prevent spamming by debounce; Ensure getting the updated carouselRef on click
  const handleForward = () => {
    if (isScrolling) return;
    const carouselEl = carouselRef?.current?.[index];

    setIsScrolling(true);
    carouselEl?.scrollBy(getScrollBy(carouselEl, 'next'), 0);

    setTimeout(() => {
      setIsScrolling(false);
    }, debounceTime);
  };

  // Prevent spamming by debounce; Ensure getting the updated carouselRef on click
  const handleBackward = () => {
    if (isScrolling) return;
    const carouselEl = carouselRef?.current?.[index];

    setIsScrolling(true);
    carouselEl?.scrollBy(getScrollBy(carouselEl, 'prev'), 0);

    setTimeout(() => {
      setIsScrolling(false);
    }, debounceTime);
  };

  return (
    <Navs
      handleBackward={handleBackward}
      handleForward={handleForward}
      isFirstChildVisible={isFirstChildVisible}
      isLastChildVisible={isLastChildVisible}
    />
  );
};

const Navs = ({
  handleBackward,
  handleForward,
  isFirstChildVisible,
  isLastChildVisible,
}: {
  handleBackward: () => void;
  handleForward: () => void;
  isFirstChildVisible: boolean;
  isLastChildVisible: boolean;
}) => (
  <>
    <li>
      <NavigationButton
        data-testid="btn-previous"
        aria-label="Scroll to previous"
        onClick={handleBackward}
        disabled={isFirstChildVisible}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
      </NavigationButton>
    </li>
    <li>
      <NavigationButton
        data-testid="btn-next"
        aria-label="Scroll to next"
        onClick={handleForward}
        disabled={isLastChildVisible}
      >
        <svg
          width="16px"
          height="16px"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </NavigationButton>
    </li>
  </>
);

const NavigationButton = ({ children, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button
    className={cn(
      'bg-background-elevation-base border-border-neutral text-neutral-strong focus:border-border-neutral flex h-8 w-8 items-center justify-center rounded-full border p-0',
      'hover:bg-background-neutral-strong hover:border-border-disabled hover:text-background-disabled',
      'disabled:hover:bg-background-elevation-base disabled:hover:border-border-neutral disabled:hover:text-neutral-strong',
      'dark:border-border-neutral-strong dark:focus:border-border-neutral-strong dark:disabled:hover:border-border-neutral-strong'
    )}
    {...rest}
  >
    {children}
  </Button>
);

const getScrollBy = (el: HTMLElement, dir: 'next' | 'prev', appleSafariOffset = 15) => {
  const clientWidth = el?.clientWidth || 0;

  /**
   * Safari has several bugs in our current Carousel implentation (utilizes scrollBy)
   * - (ipad) user is able to scroll past to the end of the carousel when clicking next
   * - (macos) user is seeing cut off cards when clicking next/prev
   * Reference: https://virginexperiencedays.atlassian.net/browse/LP-6368
   */
  if (isDeviceAppleSafari()) {
    const scrollWidth = el?.scrollWidth || 0;
    const scrollLeft = el?.scrollLeft || 0;

    const maxScrollLeft = scrollWidth - clientWidth;
    const nextFullScrollBy = clientWidth + appleSafariOffset;

    /**
     * PREV
     */
    if (dir === 'prev') {
      // partial scrollBy
      if (scrollLeft - nextFullScrollBy < 0) {
        return -scrollLeft;
      }
      // full scrollBy
      return -nextFullScrollBy;
    }

    /**
     * NEXT
     */
    // partial scrollBy
    if (scrollLeft + nextFullScrollBy > maxScrollLeft) {
      return scrollWidth - scrollLeft - clientWidth;
    }
    // full scrollBy
    return nextFullScrollBy;
  }

  return dir === 'prev' ? -clientWidth : clientWidth;
};
