import { RefObject } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { DisplayHeading } from '../../typography/DisplayHeading';
import { BodyText } from '../../typography/BodyText';
import { LinkWrap } from '../../navigation/LinkWrap';

import { CarouselNavs } from './Navs';
import { CTAButton } from './CTAButton';

export type CarouselHeadingProps = {
  className?: string;
  /**
   * Title displayed on the opposite site of the controls
   */
  title?: string;
  /**
   * Title size corresponding to sizes of `DisplayHeading`
   *
   * NOTE: This changes the heading markup, not the visual size
   */
  titleSize?: '1' | '2' | '3' | '4' | '5' | '6';
  /**
   * Description shown below title
   */
  description?: string;
  /**
   * Link for the CTA
   * When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle */
  href?: string;
  /**
   * If true, forces the CTA link to be an anchor tag to an external link, not
   * subject to Next.js client-side routing.
   */
  isHrefExternal?: boolean;
  /**
   * Title for cta
   */
  ctaTitle?: string;
  /**
   * Hide CTA on Mobile
   */
  ctaHiddenOnMobile?: boolean;
  /**
   * Debounce time to prevent spamming, recommended is 350(default)
   */
  debounceTime?: number;
  /** When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle*/
  onClick?: () => void;
  showNavigationArrows?: boolean;
  carouselRef?: RefObject<Element | Element[]>;
  lastChildRef?: RefObject<Element | Element[]>;
  firstChildRef?: RefObject<Element | Element[]>;
  /**
   * active ref index for multiple carousels (e.g. CarouselWithTabs)
   */
  activeRefIndex?: number;
};

export const CarouselHeading = ({
  className,
  title,
  titleSize,
  description,
  href,
  isHrefExternal,
  ctaTitle,
  ctaHiddenOnMobile = false,
  onClick,
  showNavigationArrows,
  carouselRef,
  lastChildRef,
  firstChildRef,
  activeRefIndex = 0,
  debounceTime,
}: CarouselHeadingProps) => {
  return (
    <div className={cn('mb-4', className)} data-testid="new-carousel">
      <div
        className="flex w-full items-baseline justify-between md:items-center"
        data-testid="carousel-heading"
      >
        {title && (
          <DisplayHeading
            className="text-neutral-strong text-xl leading-tight"
            data-testid="carousel-title"
            size={titleSize ?? '3'}
          >
            {title}
          </DisplayHeading>
        )}
        <ul className="m-0 list-none p-0 md:flex md:flex-1 md:items-center md:justify-end md:gap-1">
          {ctaTitle && (
            <div className={cn(!!title && !ctaHiddenOnMobile ? 'flex' : 'hidden', 'md:flex')}>
              {href ? (
                <li>
                  <LinkWrap
                    className={cn(
                      'text-link ml-2 whitespace-nowrap text-sm font-semibold leading-none no-underline',
                      !showNavigationArrows ? 'md:mr-0' : 'md:mr-2',
                      'md:ml-0'
                    )}
                    data-testid="carousel-link"
                    href={href}
                    isExternal={isHrefExternal}
                    target="_self"
                  >
                    {ctaTitle}
                  </LinkWrap>
                </li>
              ) : (
                <CTAButton onClick={onClick} ctaTitle={ctaTitle} />
              )}
            </div>
          )}
          {showNavigationArrows && (
            <div className="hidden md:flex md:gap-1">
              <CarouselNavs
                carouselRef={carouselRef}
                lastChildRef={lastChildRef}
                firstChildRef={firstChildRef}
                debounceTime={debounceTime}
                index={activeRefIndex}
              />
            </div>
          )}
        </ul>
      </div>
      {description && <BodyText className="text-neutral mt-2">{description}</BodyText>}
    </div>
  );
};
