export function slugToName(slug: string): string {
  return slug
    .split(/\s|-/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function nameToSlug(name: string): string {
  return name
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');
}
